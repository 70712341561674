import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import ExercicesPage from '../views/ExercicesPage.vue'
import DisciplinesPage from '../views/DisciplinesPage.vue'
import MusclesPage from '../views/MusclesPage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/Exercices',
    name: 'Exercices',
    component: ExercicesPage
  },
  {
    path: '/Disciplines',
    name: 'Disciplines',
    component: DisciplinesPage
  },


  {
    path: '/Muscles',
    name: 'Muscles',
    component: MusclesPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
