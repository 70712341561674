
<template>
  <div>
    <MenuTitre :pageTitle="title" />
    <!-- Contenu principal -->
    <div class="container-fluid">
      <div class="row content">
        <div class="col-md-2"></div>
        <div v-if="description" class="col-md-8 col-sm-12 homeSentence">
          <h2>Bienvenue sur My Coaching Helper</h2>
          <h4>votre outil pour créer des séances de sport en un clin d'œil !</h4>
          <p>
            <ul class="list-unstyled">
              <li>Commencez par personnaliser vos disciplines et vos exercices</li>
              <li>Générez vos séances personnelles ou générez des séances aléatoires</li>
            </ul>
          </p>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row content">
        <div class="col-md-2"></div>
        <div class="col-md-8 col-sm-12">
          <button 
            class="btn btn-primary" 
            @click="showRandomProgrammeFilter"
            title="Génère une séance avec des exercices aléatoire qui correspondent à vos critères">
            Séance aléatoire
          </button>
          <button 
            class="btn btn-primary" 
            @click="showManualProgrammeFilter"
            title="Affiche tous les exercices qui correspondent à vos critères">
            Séance manuelle
          </button>
          <ProgrammeFilter v-if="filterSection" 
            :manualSeance="isManual" 
            :showFilter="showFilter"
            :shouldReset="resetFilter"
            @closefilter="onClosefilter"
            @isGenerate="hideFilter"
            @showFilter="onShowFilter"
            />
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import MenuTitre from '../components/MenuTitre.vue';
  import ProgrammeFilter from '../components/ProgrammeFilter.vue';

  export default {
    name: 'HomePage',
    components:{
      MenuTitre,
      ProgrammeFilter,
    },

    data() {
      return {
        title: '',

        filterSection : false,
        isManual : false,
        showFilter : false,
        resetFilter : false,
        description : true,
      };
    },

    methods: {

      onClosefilter(){
        this.filterSection = false;
        this.description = true;
      },

      onShowFilter(data){
        this.resetFilter = false;
        this.isManual = data;
        this.showFilterProgramme();
        
      },

      showManualProgrammeFilter(){
        this.resetFilter = true;
        this.isManual = true;
        this.description = false;
        this. showFilterProgramme();
      },

      showRandomProgrammeFilter(){
        this.resetFilter = true;
        this.isManual = false;
        this.description = false;
        this. showFilterProgramme();
      },


      showFilterProgramme() {
        this.filterSection = true;
        this.showFilter = true;
      },

      hideFilter(){
        this.showFilter = false;
        this.resetFilter = false;
      },
    },
  }

</script>

<style scoped>

button {
  margin: 4px 2px;
}
.content{
  margin-left: 10px;
  margin-right: 10px;
}

.homeSentence{
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Ajouter une bordure légère pour délimiter le cadre */
  border-radius: 8px; /* Ajouter des coins arrondis */
}

.custom-image {
    width: 100%;
    max-width: 300px;
    height: auto;
}
</style>