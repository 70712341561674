<template>
  <div>
    <MenuTitre :pageTitle="title" />
  </div>

  <div v-if="modalMessageIsShow">
    <ModalMessage @messagePrinted="hideModalMessage"
      :infosMessage="infosMessage"/>
  </div>

  <div>
    <!-- Utilisation de la modale -->
    <ModalDeleteDiscipline
      v-if="showModal"
      :showModal="showModal"
      :exercices="exerciceNames"
      :disciplineToRemove = disciplineToRemove
      @close="closeModal"
      @ChangeDone="onChangeDone"
    />
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="scrollable-table col-md-8 col-sm-12" ref="disciplineTableContainer">
        <table class="table">
          <thead>
            <tr>
              <th>Disciplines</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr translate="no" v-for="discipline in disciplines" :key="discipline.id">
              <td>{{ discipline.name }}</td>
              <td><button class="btn btn-light" @click="removeDiscipline(discipline.name)" title="Supprimer une discipline"><i class="bi bi-trash text-danger"></i></button></td>
            </tr>
          </tbody>
          <tfoot>
            <tr v-if="errorMessage">
              <td colspan="2"><p style="color: red;">{{ errorMessage }}</p></td>
            </tr>
            <tr>
              <td class="text-end">
                <input class="new-sport" v-model="newDiscipline" placeholder="Insérez le nom de la discipline" @keyup.enter="addDiscipline" />
              </td>
              <td>
                <button class="btn btn-light" @click="addDiscipline" title="Ajouter une nouvelle discipline"><i class="bi bi-plus text-success"></i></button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>

import ModalDeleteDiscipline from '../components/ModalDeleteDiscipline.vue';
import MenuTitre from '../components/MenuTitre.vue';
import ModalMessage from '../components/ModalMessage.vue';
import { insertDiscipline, fetchAllDisciplines, deleteDiscipline, getExercicesByDiscipline } from'../Database.js';
import axios from 'axios';

export default {
  components: {
    MenuTitre,
    ModalMessage,
    ModalDeleteDiscipline,
  },
  data() {
    return {
      newDiscipline: '',  // Stocke le nom de la nouvelle discipline à ajouter
      disciplines: [],
      title: 'Disciplines',  // Liste des disciplines récupérées de la base de données
      errorMessage:'',
      
      modalMessageIsShow: false,

      showModal : false,
      exerciceNames: [],
      disciplineToRemove : "",


      infosMessage:"",
    };
  },

  methods: {

    closeModal() {
      this.showModal = false;
    },

    onChangeDone() {
      this.showModal = false;
      this.removeDiscipline(this.disciplineToRemove);
    },

    hideModalMessage(){
      this.modalMessageIsShow = false;
      this.infosMessage ="";
    },

    showModalMessage(){
      this.modalMessageIsShow = true;
      setTimeout(()=>{
        this.hideModalMessage();
      },1000);
    },

    async loadDisciplines() {
      // Récupérer toutes les disciplines depuis la base de données
      this.disciplines = await fetchAllDisciplines();
    },

    async addDiscipline() {
      this.errorMessage = "";
      if (this.newDiscipline.trim()) {
        // Ajouter la nouvelle discipline dans la base de données
        try{
          await insertDiscipline(this.newDiscipline.trim());
          await axios.post("https://my-coaching-helper.go.yj.fr/api/ajouter_discipline.php",
          {
            nom: this.newDiscipline.trim(), // Envoyer le nom de la discipline
          }
        );
        }
        catch(error){
          this.errorMessage=error.message;
          this.newDiscipline = "";
          return;
          
        }
        // Recharger les disciplines après l'ajout
        await this.loadDisciplines();
        this.infosMessage ="Discipline ajouté avec succès";
        this.showModalMessage();
        // Réinitialiser le champ d'entrée
        this.newDiscipline = '';
        this.errorMessage ="";
        this.$refs.disciplineTableContainer.scrollTop = this.$refs.disciplineTableContainer.scrollHeight;
      }
      else{
        this.errorMessage="Veuillez entrez le nom de la discipline";

      }
    },

    async removeDiscipline(name) {
      const exerciceList = await getExercicesByDiscipline(name);
      this.disciplineToRemove = name;
      if (exerciceList.length > 0){
        this.exerciceNames = exerciceList;
        this.showModal = true;
      }else{
        try {
          // Supprimer la discipline de la base de données (à implémenter dans votre fichier Dexie)
          await deleteDiscipline(name)
          // Recharger les disciplines après la suppression
          await this.loadDisciplines();
          this.infosMessage ="Discipline supprimé avec succès";
          this.showModalMessage();
        } catch (error) {
          console.error('Failed to remove discipline:', error);
        }
        this.disciplineToRemove = "";
      }
    }
  },

  async mounted() {
    // Charger les disciplines lorsqu'on monte le composant
    await this.loadDisciplines();
  },
}

</script>

<style scoped>

.scrollable-table {
  max-width: 100%;            /* Largeur de la table */
  max-height: 70vh;      /* Hauteur maximale de l'enveloppe de la table */
  overflow-y: auto;       /* Activer le défilement vertical */
  border: 1px solid #ccc; /* Ajouter une bordure légère pour délimiter le cadre */
  border-radius: 8px; /* Ajouter des coins arrondis */
}

thead, tfoot {
  background-color: #f9f9f9;  /* Couleur de fond pour différencier le thead et tfoot */
  position: sticky;      /* Fixer la position */
  top: 0;                /* Fixer le thead au sommet */
  z-index: 1;            /* Assurer que le thead est au-dessus des autres éléments */
}

tfoot {
  bottom: 0;             /* Fixer le tfoot au bas */
}

.new-sport{
  max-width: 80%;
}

button {
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 6px;
}

td {
  vertical-align: middle;
}

.table{
  margin: auto;
  width: 100%;
}

.row{
  margin-left: 10px;
  margin-right: 10px;
}
</style>
