<template>
  <div>
    <MenuTitre :pageTitle="title" />
  </div>
  <div v-if="modalMessageIsShow">
    <ModalMessage @messagePrinted="hideModalMessage"
      :infosMessage="infosMessage"/>
  </div>
  <div>
    <ModalAddChangeExercice @exerciceAdded="onExerciceAdded" @cancel="onCancel" 
      :showModal="modalIsShow"
      :oldName ="oldName "
      :oldMuscles ="oldMuscles"
      :oldDiscipline = "oldDiscipline" 
      :oldDifficulty = "oldDifficulty" />
  </div>

  <div v-if="contextMenu.visible" :style="{ top: contextMenu.y + 'px', left: contextMenu.x + 'px' }" class="context-menu"   ref="contextMenu">
    <button class="btn btn-primary" @click="modifyExercice(contextMenu.exercice.name, contextMenu.exercice.muscles, contextMenu.exercice.disciplines, contextMenu.exercice.difficulty)">Modifier</button>
    <button class="btn btn-primary" @click="removeExercice(contextMenu.exercice.name)">Supprimer</button>
  </div>
  
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="scrollable-table col-md-8 col-sm-12">
        <table class="table">
          <thead>
            <tr>
              <th>Nom de l'exercice</th>
              <th>Muscles associés</th>
              <th>Disciplines associées</th>
              <th>Difficulté</th>
              <th class="d-none d-md-table-cell"></th>
            </tr>
          </thead>
          <tbody>
            <tr @touchstart="startLongPress($event, exercice)" @touchend="clearLongPress" v-for="exercice in exercices" :key="exercice.id">
              <td translate="no">{{ exercice.name }}</td>
              <td>{{ exercice.muscles }}</td>
              <td translate="no">{{ exercice.disciplines }}</td>
              <td><i
                  v-for="n in 5"
                  :key="n"
                  class="star bi"
                  :class="n <= exercice.difficulty ? 'bi-star-fill selected' : 'bi-star'"
                ></i></td>
              <td class="d-none d-md-table-cell">
                <button class="btn btn-light" @click="modifyExercice(exercice.name, exercice.muscles, exercice.disciplines, exercice.difficulty)"><i class="bi bi-pen"></i></button>
                <button class="btn btn-light" @click="removeExercice(exercice.name)"><i class="bi bi-trash text-danger"></i></button>  
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5" class="text-end">
                <button class="btn btn-primary" @click="AddExercice">Ajouter un exercice</button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>

<script>
import ModalMessage from '../components/ModalMessage.vue';
import ModalAddChangeExercice from '../components/ModalAddChangeExercice.vue';
import MenuTitre from '../components/MenuTitre.vue';
import { fetchAllExercices,deleteExercice } from'../Database.js';

export default {
  components: {
    MenuTitre,
    ModalAddChangeExercice,
    ModalMessage,
  },

  data() {
    return {
      title: 'Exercices',
      exercices: [],

      modalIsShow : false,
      oldName : '', 
      oldMuscles : [], 
      oldDiscipline : [], 
      oldDifficulty : 0,

      longPressTimeout: null,
      contextMenu: {
        visible: false,
        x: 0,
        y: 0,
        exercice : null,
      },

      modalMessageIsShow: false,
      infosMessage:"",
    };
  },

  methods: {

    hideModalMessage(){
      this.modalMessageIsShow = false;
      this.infosMessage ="";
    },

    showModalMessage(){
      this.modalMessageIsShow = true;
      setTimeout(()=>{
        this.hideModalMessage();
      },1000);
    },

    startLongPress(event, exercice) {
      // Enregistre la position de l'événement
      const posX = event.clientX || event.touches[0].clientX;
      const posY = event.clientY || event.touches[0].clientY;

      this.longPressTimeout = setTimeout(() => {
        this.showContextMenu(posX, posY, exercice);
      }, 600); // Délai de 600 ms pour simuler le long press
    },
    clearLongPress() {
      // Annuler le maintien long si l'utilisateur relâche trop tôt
      clearTimeout(this.longPressTimeout);
    },

    showContextMenu(x, y, exercice) {
  // Récupérer les dimensions du menu contextuel
  const menuWidth = 150; // Ajuste la largeur en fonction de ton CSS ou en dynamique
  const menuHeight = 100; // Ajuste la hauteur en fonction de ton CSS ou en dynamique
  
  // Récupérer les dimensions de la fenêtre
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  
  // Ajuster la position si le menu dépasse du côté droit ou du bas
  if (x + menuWidth > windowWidth) {
    x = windowWidth - menuWidth - 10; // Réduire de 10px pour éviter qu'il touche le bord
  }
  if (y + menuHeight > windowHeight) {
    y = windowHeight - menuHeight - 10; // Réduire de 10px pour éviter qu'il touche le bord
  }

  // Afficher le menu avec les nouvelles coordonnées
  this.contextMenu = {
    visible: true,
    x: x,
    y: y,
    exercice: exercice,
  };
},
    
    hideContextMenu() {
      this.contextMenu.visible = false;
    },

    showModal(){
      this.modalIsShow = true;
    },

    onCancel(){
      this.modalIsShow = false;
    },

    onExerciceAdded() {
      this.loadExercice();
      this.modalIsShow = false;
      this.showModalMessage();
      this.oldName = '';
      this.oldMuscles = [];
      this.oldDiscipline = [];
      this.oldDifficulty = 0;
    },

    AddExercice(){
      this.infosMessage ="Exercice ajouté avec succès";
      this.showModal();
    },

    modifyExercice(name, muscles, discipline, difficulty) {
      this.infosMessage ="Exercice modifié avec succès";
      this.oldName = name;
      this.oldMuscles = muscles.split(", ");
      this.oldDiscipline = discipline.split(", ");
      this.oldDifficulty = difficulty;
      this.hideContextMenu();
      this.showModal();
      }, 

    async loadExercice() {
      // Récupérer toutes les disciplines depuis la base de données
      this.exercices = await fetchAllExercices();
    },

    async removeExercice(name) {
      try {
        // Supprimer la discipline de la base de données
        await deleteExercice(name);
        // Recharger les disciplines après la suppression
        await this.loadExercice();
      } catch (error) {
        this.infosMessage ="ERREUR: Echec de la suppression de l'exercice";
        this.showModalMessage();
      }
      this.hideContextMenu();
      this.infosMessage ="Exercice supprimé avec succès";
      this.showModalMessage();
    },

    handleClickOutside(event) {
      const contextMenuElement = this.$refs.contextMenu;
      if (contextMenuElement && !contextMenuElement.contains(event.target)) {
        this.hideContextMenu();
      }
    }
  },
  

  async mounted() {
    // Charger les disciplines lorsqu'on monte le composant
    await this.loadExercice();
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {
    // Supprimer l'événement global lorsqu'on détruit le composant
    document.removeEventListener('click', this.handleClickOutside);
  }
}

</script>

<style scoped>
.star {
  color: gray;
}
.star.selected {
  color: gold;
}

.scrollable-table {
  max-width: 100%;            /* Largeur de la table */
  max-height: 70vh;      /* Hauteur maximale de l'enveloppe de la table */
  overflow-y: auto;       /* Activer le défilement vertical */
  border: 1px solid #ccc; /* Ajouter une bordure légère pour délimiter le cadre */
  border-radius: 8px; /* Ajouter des coins arrondis */
}

thead, tfoot {
  position: sticky;      /* Fixer la position */
  top: 0;                /* Fixer le thead au sommet */
  z-index: 1;            /* Assurer que le thead est au-dessus des autres éléments */
}

button {
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 6px;
}

td {
  vertical-align: middle;
}

.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.context-menu button {
  display: block;
  width: 100%;
}

.table{
  margin: auto;
  width: 100%;
}


.row{
  margin-left: 10px;
  margin-right: 10px;
}
</style>

