import Dexie from 'dexie';

// Créer une instance de base de données Dexie
const db = new Dexie('SportDataBase');

// Définir les schémas des tables
db.version(1).stores({
  disciplines: '++id,name',
  exercices: '++id,name, difficulty',
  muscles: '++id,name, bodypart',
  exercice_muscles: '++id,exercice_id,muscle_id',
  exercice_disciplines: '++id,exercice_id, discipline_id'
});

db.open().catch((err) => {
  console.error("Failed to open the database: " + (err.stack || err));
});

// Ajouter des données par défaut lors de la création de la base de données

const muscles = [
  { name: 'Biceps', bodypart: 'Haut du corps' },
  { name: 'Triceps', bodypart: 'Haut du corps' },
  { name: 'Deltoïdes', bodypart: 'Haut du corps' },
  { name: 'Pectoraux', bodypart: 'Haut du corps' },
  { name: 'Abdominaux', bodypart: 'Haut du corps' },
  { name: 'Quadriceps', bodypart: 'Bas du corps' },
  { name: 'Ischio-jambiers', bodypart: 'Bas du corps' },
  { name: 'Mollets', bodypart: 'Bas du corps' },
  { name: 'Fessiers', bodypart: 'Bas du corps' },
  { name: 'Trapèze', bodypart: 'Haut du corps' },
  { name: 'Avant-bras', bodypart: 'Haut du corps' }
];

db.on('populate', async () => {
  await db.muscles.bulkAdd(muscles);
});


export const normalizeName = (name) => {
  // Supprime les espaces en début et en fin, remplace les espaces multiples par un seul espace
  let normalized = name.trim().toLowerCase().replace(/\s+/g, ' ');

  // Remet la première lettre en majuscule
  return normalized.charAt(0).toUpperCase() + normalized.slice(1);
}

// Ajouter une discipline
export const insertDiscipline = async (name) => {
  const normalizedName = normalizeName(name);
  const discipline = await db.disciplines.where('name').equals(normalizedName).first();

  if (discipline) {
    throw new Error(`La discipline "${normalizedName}" existe déjà.`);
  }
  else {
    await db.disciplines.add({ name: normalizedName });
  }
};

// Ajouter un exercice
export const insertExercice = async (name, difficulty) => {
  const normalizedName = normalizeName(name);
  const exercice = await db.exercices.where('name').equals(normalizedName).first();

  if (exercice) {
    throw new Error(`L'exercice "${normalizedName}" existe déjà.`);
  }
  else {
    await db.exercices.add({ name: normalizedName, difficulty: difficulty });
  }
};

// Ajouter un muscle
export const insertMuscle = async (name, bodypart) => {
  const normalizedName = normalizeName(name);
  const muscle = await db.muscles.where('name').equals(normalizedName).first();

  if (muscle) {
    throw new Error(`Le muscle "${normalizedName}" existe déjà.`);
  }
  else {
    await db.muscles.add({ name: normalizedName, bodypart: bodypart });
  }
};

// Associer un exercice à une liste de muscles et de disciplines
export const associateMuscleExerciceDisciplines = async (exerciceName, muscleNames, disciplineNames) => {
  try {
    const normalizedExerciceName = normalizeName(exerciceName);
    const exercice = await db.exercices.where('name').equals(normalizedExerciceName).first();

    if (!exercice) {
      throw new Error('Exercice not found');
    }
    await associateExerciceMuscles(exerciceName, muscleNames)

    await associateExerciceDisciplines(exerciceName, disciplineNames);

  } catch (error) {
    console.error('Failed to associate muscles, exercice, and disciplines:', error);
  }
};

export const associateExerciceMuscles = async (exerciceName, muscleNames) => {
  try {
    const normalizedExerciceName = normalizeName(exerciceName);
    const exercice = await db.exercices.where('name').equals(normalizedExerciceName).first();

    if (!exercice) {
      throw new Error('Exercice not found');
    }

    // Traiter les muscles
    for (const muscleName of muscleNames) {
      const normalizedMuscleName = normalizeName(muscleName);
      const muscle = await db.muscles.where('name').equals(normalizedMuscleName).first();

      if (muscle) {
        await db.exercice_muscles.add({ exercice_id: exercice.id, muscle_id: muscle.id });
      } else {
        console.warn(`Muscle "${muscleName}" not found`);
      }
    }

  } catch (error) {
    console.error('Failed to associate exercice and muscles:', error);
  }
};

export const associateExerciceDisciplines = async (exerciceName, disciplineNames) => {
  try {
    const normalizedExerciceName = normalizeName(exerciceName);
    const exercice = await db.exercices.where('name').equals(normalizedExerciceName).first();

    if (!exercice) {
      throw new Error('Exercice not found');
    }

    // Traiter les disciplines
    for (const disciplineName of disciplineNames) {
      const normalizeddisciplineName = normalizeName(disciplineName);
      const discipline = await db.disciplines.where('name').equals(normalizeddisciplineName).first();

      if (discipline) {
        await db.exercice_disciplines.add({ exercice_id: exercice.id, discipline_id: discipline.id });
      } else {
        console.warn(`Discipline "${disciplineName}" not found`);
      }
    }

  } catch (error) {
    console.error('Failed to associate exercice and disciplines:', error);
  }
};


// Récupérer toutes les disciplines
export const fetchAllDisciplines = async () => {
  try {
    return await db.disciplines.toArray();
  } catch (error) {
    console.error('Failed to fetch disciplines:', error);
  }
};

// Récupérer tous les exercices
export const fetchAllExercices = async () => {
  try {
    const exercicesList = await db.exercices.toArray();
    const exercices = await Promise.all(
      exercicesList.map(async (exercice) => {
        const muscles = await getMusclesForExercice(exercice.name);
        const disciplines = await getDisciplinesForExercice(exercice.name);
        return {
          ...exercice,
          muscles: muscles.join(', '), // Convertir le tableau en chaîne de caractères
          disciplines: disciplines.join(', '), // Convertir le tableau en chaîne de caractères
        };
      })
    );
    return exercices;
  } catch (error) {
    console.error('Failed to fetch exercices:', error);
  }
};

export const fetchAllExercicesByDifficulty = async (difficulty) => {
  try {
    const exercicesList = await db.exercices.where('difficulty').belowOrEqual(difficulty).toArray();
    const exercices = await Promise.all(
      exercicesList.map(async (exercice) => {
        const muscles = await getMusclesForExercice(exercice.name);
        const disciplines = await getDisciplinesForExercice(exercice.name);
        return {
          ...exercice,
          muscles: muscles.join(', '), // Convertir le tableau en chaîne de caractères
          disciplines: disciplines.join(', '), // Convertir le tableau en chaîne de caractères
        };
      })
    );
    return exercices;
  } catch (error) {
    console.error('Failed to fetch exercices:', error);
  }
};

// Récupérer tous les muscles
export const fetchAllMuscles = async () => {
  try {
    return await db.muscles.toArray();
  } catch (error) {
    console.error('Failed to fetch muscles:', error);
  }
};

export const fetchMusclesBybodypart = async (bodypart) => {
  try {
    return await db.muscles.where('bodypart').equals(bodypart).toArray();
  } catch (error) {
    console.error('Failed to fetch muscles by bodypart:', error);
  }
};


export const getMusclesForExercice = async (name) => {
  try {
    // Rechercher l'exercice par son nom
    const normalizedName = normalizeName(name);
    const exercice = await db.exercices.where('name').equals(normalizedName).first();

    if (!exercice) {
      console.error('Exercice non trouvé');
      return [];
    }

    // Récupérer toutes les associations muscles pour cet exercice
    const associations = await db.exercice_muscles.where('exercice_id').equals(exercice.id).toArray();

    // Récupérer tous les muscles associés à cet exercice
    const muscles = await Promise.all(
      associations.map(async (association) => {
        return await db.muscles.get(association.muscle_id);
      })
    );

    // Extraire les noms de muscles
    const muscleNames = muscles.map(muscle => muscle.name);

    return muscleNames;
  } catch (error) {
    console.error('Erreur lors de la récupération des noms de muscles :', error);
    return [];
  }
};

export const getDisciplinesForExercice = async (name) => {
  try {
    // Rechercher l'exercice par son nom
    const normalizedName = normalizeName(name);
    const exercice = await db.exercices.where('name').equals(normalizedName).first();

    if (!exercice) {
      console.error('Exercice non trouvé');
      return [];
    }

    // Récupérer toutes les associations disciplines pour cet exercice
    const associations = await db.exercice_disciplines.where('exercice_id').equals(exercice.id).toArray();

    // Récupérer toutes les disciplines associées
    const disciplines = await Promise.all(
      associations.map(async (association) => {
        return await db.disciplines.get(association.discipline_id);
      })
    );
    // Extraire les noms d'exercices
    const disciplinesNames = disciplines.map(discipline => discipline.name);

    return disciplinesNames;
  } catch (error) {
    console.error('Erreur lors de la récupération des noms de disciplines :', error);
    return [];
  }
};

export const deleteExercice = async (name) => {
  try {
    // Rechercher l'exercice par son nom
    //const exercice = await db.exercices.where('id').equals(id).first();
    const normalizedName = normalizeName(name);
    const exercice = await db.exercices.where('name').equals(normalizedName).first();

    if (exercice) {
      // Rechercher et supprimer les références dans exercice_disciplines
      const exerciceDisciplines = await db.exercice_disciplines.where('exercice_id').equals(exercice.id).toArray();

      // Suppression des références associées
      for (const item of exerciceDisciplines) {
        await db.exercice_disciplines.delete(item.id);
      }
      // Rechercher et supprimer les références dans exercice_muscles
      const exerciceMuscles = await db.exercice_muscles.where('exercice_id').equals(exercice.id).toArray();

      // Suppression des références associées
      for (const item of exerciceMuscles) {
        await db.exercice_muscles.delete(item.id);
      }

      // Supprimer l'exercice
      await db.exercices.delete(exercice.id);
    } else {
      console.error(`Aucun exercice trouvé avec le nom : ${name}`);
    }
  } catch (error) {
    console.error('Échec de la suppression de l\'exercice :', error);
  }
};


export const deleteDiscipline = async (DisciplineName) => {
  try {
    // Rechercher l'exercice par son nom
    const normalizeDisciplineName = normalizeName(DisciplineName);
    const discipline = await db.disciplines.where('name').equals(normalizeDisciplineName).first();

    if (discipline) {
      // Si l'exercice est trouvé, le supprimer en utilisant son id
      await db.exercice_disciplines.where('discipline_id').equals(discipline.id).delete();
      await db.disciplines.delete(discipline.id);
    } else {
      console.error(`Aucune discipline trouvé avec le nom : ${DisciplineName}`);
    }
  } catch (error) {
    console.error('Échec de la suppression de l\'exercice :', error);
  }
};

export const deleteMuscle = async (MuscleName) => {
  try {
    // Rechercher l'exercice par son nom
    const normalizeMuscleName = normalizeName(MuscleName);
    const muscle = await db.muscles.where('name').equals(normalizeMuscleName).first();

    if (muscle) {
      // Si l'exercice est trouvé, le supprimer en utilisant son id
      await db.exercice_muscles.where('muscle_id').equals(muscle.id).delete();
      await db.muscles.delete(muscle.id);
    } else {
      console.error(`Aucun exercice trouvé avec le nom : ${MuscleName}`);
    }
  } catch (error) {
    console.error('Échec de la suppression de l\'exercice :', error);
  }
};

export const getExercicesByDiscipline = async (disciplineName) => {

  // Obtenez la discipline avec le nom donné
  const normalizeDisciplineName = normalizeName(disciplineName);
  const discipline = await db.disciplines.where('name').equals(normalizeDisciplineName).first();
  if (!discipline) {
    return []; // Si la discipline n'existe pas, retournez un tableau vide
  }

  // Obtenez les exercices associés uniquement à cette discipline
  const exerciceIds = await db.exercice_disciplines
    .where('discipline_id')
    .equals(discipline.id)
    .toArray();  // Récupère tous les enregistrements correspondant à la discipline

  // Extraire uniquement les valeurs de `exercice_id`
  const exerciceIdList = exerciceIds.map(item => item.exercice_id);

  // Filtrer pour obtenir uniquement les exercices qui n'ont pas d'autres disciplines
  const filteredExerciceIds = [];
  for (const exerciceId of exerciceIdList) {
    const count = await db.exercice_disciplines
      .where('exercice_id')
      .equals(exerciceId)
      .count();


    // Si l'exercice n'a qu'une discipline (celle sélectionnée), l'ajouter au tableau filtré
    if (count === 1) {
      filteredExerciceIds.push(exerciceId);
    }
  }

  // Obtenez les exercices avec leurs ids et noms
  const exercices = await db.exercices
    .where('id')
    .anyOf(filteredExerciceIds)
    .toArray();

  // Retournez un tableau d'objets { id, name } pour chaque exercice
  return exercices.map(exercice => ({
    id: exercice.id,
    name: exercice.name,
  }));
}

export const getExercicesByMuscles = async (musclesName) => {

  // Obtenez la discipline avec le nom donné
  const normalizeMuscleName = normalizeName(musclesName);
  const muscle = await db.muscles.where('name').equals(normalizeMuscleName).first();
  if (!muscle) {
    return []; // Si le muscle n'existe pas, retournez un tableau vide
  }

  // Obtenez les exercices associés uniquement à cette discipline
  const exerciceIds = await db.exercice_muscles
    .where('muscle_id')
    .equals(muscle.id)
    .toArray();  // Récupère tous les enregistrements correspondant à la discipline

  // Extraire uniquement les valeurs de `exercice_id`
  const exerciceIdList = exerciceIds.map(item => item.exercice_id);

  // Filtrer pour obtenir uniquement les exercices qui n'ont pas d'autres disciplines
  const filteredExerciceIds = [];
  for (const exerciceId of exerciceIdList) {
    const count = await db.exercice_muscles
      .where('exercice_id')
      .equals(exerciceId)
      .count();


    // Si l'exercice n'a qu'une discipline (celle sélectionnée), l'ajouter au tableau filtré
    if (count === 1) {
      filteredExerciceIds.push(exerciceId);
    }
  }

  // Obtenez les exercices avec leurs ids et noms
  const exercices = await db.exercices
    .where('id')
    .anyOf(filteredExerciceIds)
    .toArray();

  // Retournez un tableau d'objets { id, name } pour chaque exercice
  return exercices.map(exercice => ({
    id: exercice.id,
    name: exercice.name,
  }));
}





export default db;


