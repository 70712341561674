<template>
  <div v-if="showFilter" class="container-fluid">
      <div class="row filtre">
        <div class="col-md-4 col-sm-12">
          <h5>Sélectionnez les disciplines à travailler</h5>
          <select style="margin-bottom: 10px;" v-model="disciplineSelected">
            <option value="" disabled>Sélectionnez une option</option>
            <option translate="no" v-for="(item, index) in disciplines" :key="index" :value="item.name">{{ item.name }}</option>
          </select>
          
        </div>
        <div class="col-md-4 col-sm-12">
          <div v-if="!manualSeance">
            <h5>Nombre d'exercices dans la séance</h5>
            <input style="margin-bottom: 10px;" v-model="numberOfExercice" type="number" min="1" step="1" max="30"/>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <h5>Sélectionnez la difficulté maximale</h5>
            <div class="rating">
              <i v-for="n in 5"
                :key="n"
                class="star bi"
                :class="n <= difficulty ? 'bi-star-fill selected' : 'bi-star'"
                @click="selectRating(n)">
              </i>
            </div>
        </div>
      </div>
      <div class="row filtre">
        <div class="col-12">
          <h5>Sélectionnez les muscles à travailler</h5>
          <div>
            <div class="checkbox-muscle">
              <label>
                <input type="checkbox" v-model="muscleHautDuCorpsState" @change="selectAllMuscleHaut">Haut du corps :
              </label>
            </div>
            <div class="checkbox-container checkbox-muscle">
              <div v-for="(item, index) in muscleHautDuCorps" :key="index" class="checkbox-item">
                <label>
                  <input type="checkbox" :value="item.name" v-model="muscleSelected"> 
                    {{ item.name }}
                </label>
              </div>
            </div>
            <div class="checkbox-muscle">
              <label>
                <input type="checkbox" v-model="muscleBasDuCorpsState" @change="selectAllMuscleBas">Bas du corps :
              </label>
            </div>
            <div class="checkbox-container checkbox-muscle">
              <div v-for="(item, index) in muscleBasDuCorps" :key="index" class="checkbox-item">
                <label>
                  <input type="checkbox" :value="item.name" v-model="muscleSelected">
                    {{ item.name }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <button class="btn btn-primary selectAll" @click="selectAllMuscles">{{textSelectAll}}</button>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-primary" @click="genererSeance">Générer</button>
        <button class="btn btn-primary" @click="reinitialise">Réinitialiser</button>
        <button class="btn btn-primary" @click="close">Fermer</button>
      </div>
  </div>

  <p v-if="errorMessage" style="color: red;">{{ errorMessage }}</p>
  <div class="container-fluid" v-if="!showFilter">
    <button class="btn btn-primary" @click="showFilterSection">Modifier les critères</button>
    <div class="row">
      <div class="resultTable" ref="results">
        <table  class="table">
          <thead>
            <tr>
              <th>Nom de l'exercice</th>
              <th>Muscles associés</th>
              <th>Disciplines associées</th>
              <th>Difficulté</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="exercice in filteredExercices" :key="exercice.id">
              <td>{{ exercice.name }}</td>
              <td>{{ exercice.muscles }}</td>
              <td>{{ exercice.disciplines }}</td>
              <td><i
                  v-for="n in 5"
                  :key="n"
                  class="star bi"
                  :class="n <= exercice.difficulty ? 'bi-star-fill selected' : 'bi-star'"
                ></i></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  
</template>

<script>
import { fetchAllDisciplines,fetchAllExercicesByDifficulty,fetchAllMuscles, fetchMusclesBybodypart } from'../Database.js';

export default {
  name : 'ProgrammeFilter',
  emits: ['isGenerate', 'showFilter', 'closefilter'],

  props: {
    manualSeance: Boolean,
    showFilter : Boolean,
    shouldReset: Boolean,
    },

  watch:{
    showFilter(newVal){
      if(newVal === true){
        this.errorMessage='';
      }
      if (this.shouldReset) {
        this.reinitialise();
      }
    },

    muscleSelected(){
      if (this.muscleSelected.length == this.muscles.length) {
      this.textSelectAll = "Tout désélectionner"

      this.muscleBasDuCorpsState = true;
      this.muscleHautDuCorpsState = true;
      }
      else if (this.muscleSelected.length != this.muscles.length){
        this.textSelectAll = "Tout sélectionner"
        if(this.muscleBasDuCorps.map(muscle => muscle.name).every(element => this.muscleSelected.includes(element))){
          this.muscleBasDuCorpsState = true;
        }
        else{
          this.muscleBasDuCorpsState = false;
        }
        if(this.muscleHautDuCorps.map(muscle => muscle.name).every(element => this.muscleSelected.includes(element))){
          this.muscleHautDuCorpsState = true;
        }
        else{
          this.muscleHautDuCorpsState = false;
        }
      }
    },
  },
  

  data() {
    return {
      muscleHautDuCorpsState : false,
      muscleBasDuCorpsState : false,
      muscleHautDuCorps : [],
      muscleBasDuCorps : [],
      muscleSelected: [],
      disciplineSelected: "",
      disciplines: [],  
      muscles: [],
      filteredExercices: [],
      difficulty : 0,
      numberOfExercice : 0,
      errorMessage : "",
      textSelectAll : "Tout sélectionner",
    };
  },

  methods: {

    showFilterSection(){
      this.$emit('showFilter', this.manualSeance);
    },

    selectAllMuscleBas(){
      if (this.muscleBasDuCorpsState === true){
        this.muscleSelected = this.muscleSelected.concat(this.muscleBasDuCorps.map(muscle => muscle.name));
      }
      else{
        const filteredList = this.muscleSelected.filter(item => !this.muscleBasDuCorps.map(muscle => muscle.name).includes(item));
        this.muscleSelected = filteredList;
      }
    },

    selectAllMuscleHaut(){
      if (this.muscleHautDuCorpsState === true){
        this.muscleSelected = this.muscleSelected.concat(this.muscleHautDuCorps.map(muscle => muscle.name));
      }
      else{
        const filteredList = this.muscleSelected.filter(item => !this.muscleHautDuCorps.map(muscle => muscle.name).includes(item));
        this.muscleSelected = filteredList;
      }
    },

    async loadDiscipline() {
      try {
        this.disciplines = await fetchAllDisciplines(); // Récupérer toutes les disciplines
      } catch (error) {
        console.error('Erreur lors de la récupération des options :', error);
      }
    },

    async loadMuscle() {
      try {
        this.muscles = await fetchAllMuscles(); // Récupérer toutes les muscles
        this.muscleBasDuCorps = await fetchMusclesBybodypart("Bas du corps");
        this.muscleHautDuCorps = await fetchMusclesBybodypart("Haut du corps")
      } catch (error) {
        console.error('Erreur lors de la récupération des options :', error);
      }
    },

    checkFilter(){
      if (this.disciplineSelected === ""){
        this.errorMessage = "Veuillez sélectionner une discipline";
        return false;
      }
      else if(this.numberOfExercice == 0 && !this.manualSeance){
        this.errorMessage = "Veuillez sélectionner un nombre  d'exercice pour la séance";
        return false;
      }
      else if (this.difficulty === 0){
        this.errorMessage = "Veuillez sélectionner une difficulté maximale";
        return false;
      }
      else if (this.muscleSelected.length === 0 ){
        this.errorMessage = "Veuillez sélectionner au moins un muscle";
        return false;
      }
      return true
    },

    async filtreExercice(){
      const allExercices = await fetchAllExercicesByDifficulty(this.difficulty);
      return allExercices.filter((exercice) => {
          // Vérifie s'il y a au moins une correspondance de muscle
          const musclesMatch = this.muscleSelected.some(muscle => exercice.muscles.includes(muscle));
          
          // Vérifie s'il y a au moins une correspondance de discipline
          const disciplinesMatch = exercice.disciplines.includes(this.disciplineSelected);
          
          // Retourne vrai seulement s'il y a au moins une correspondance dans les deux
          return musclesMatch && disciplinesMatch;
        });
    },

    ramdomExercice(resultList){
      for (let i = 0; i < this.numberOfExercice; i++) {
        // Choix d'un index aléatoire dans la copie de la liste
        const indexAleatoire = Math.floor(Math.random() * resultList.length);
        
        // Ajout de l'élément à la liste de résultats
        this.filteredExercices.push(resultList[indexAleatoire]);
        
        // Suppression de l'élément tiré de la copie pour éviter les doublons
        resultList.splice(indexAleatoire, 1);
      }
    },

    checkNbrfilteredExercices(){
      if(this.filteredExercices.length === 1){
          this.errorMessage = `Seulement ${this.filteredExercices.length} exercice correspond aux critères`;
        }
        else if(this.filteredExercices.length === 0){
          this.errorMessage = `Aucun exercice ne correspond aux critères`;
        }
        else{
          this.errorMessage = `Seulement ${this.filteredExercices.length} exercices correspondent aux critères`;
        }

    },

    close(){
      this.$emit('closefilter');
    },

    async genererSeance() {
      this.filteredExercices = [];
      this.errorMessage = "";

      if (!this.checkFilter())return;

      let resultList = await this.filtreExercice();
      if(!this.manualSeance){
        if(resultList.length>=this.numberOfExercice){
          this.ramdomExercice(resultList);
        }
        else{
          this.filteredExercices = resultList
          this.checkNbrfilteredExercices();
        }
      }
      else{
        this.filteredExercices = resultList
        this.checkNbrfilteredExercices();
      }
      this.$emit('isGenerate');
    },


    reinitialise(){
      this.muscleSelected =  [];
      this.disciplineSelected = "";
      this.difficulty = 0;
      this.numberOfExercice = 0;
      this.result = false;
    },

    selectRating(rating) {
        this.difficulty = rating;
    },

    selectAllMuscles(){
      if (this.muscleSelected.length != this.muscles.length) {
        this.muscleSelected = this.muscles.map(muscle => muscle.name);
      }
      else if (this.muscleSelected.length == this.muscles.length){
        this.muscleSelected = [];
      }
    },

    selectAllDisciplines(){
      if (this.disciplineSelected.length != this.disciplines.length) {
        this.disciplineSelected = this.disciplines.map(muscle => muscle.name);

      }
      else if (this.disciplineSelected.length == this.disciplines.length){
        this.disciplineSelected = [];
      }
    },
  },

  async mounted() {
    // Charger les disciplines lorsqu'on monte le composant
    await this.loadDiscipline();
    await this.loadMuscle();
  },
}

</script>


<style scoped>

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Espace entre les checkboxes */
}

/* Chaque checkbox-item occupe une partie de la ligne */
.checkbox-item {
  flex: 1 1 150px; /* Minimum de 150px, mais peut s'étendre */
  display: flex;
  align-items: center;
}

.checkbox-muscle{
  text-align: left;
  padding-bottom:5px;
  padding-top: 5px;
}

.star {
  color: gray;
}
.star.selected {
  color: gold;
}

button {
  margin: 4px 2px;
}

.selectAll {
  width: 180px;
}


.filtre {
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Ajouter une bordure légère pour délimiter le cadre */
  border-radius: 8px; /* Ajouter des coins arrondis */
}

h5{
  margin: 10px;
}

.resultTable{
  border: 1px solid #ccc; /* Ajouter une bordure légère pour délimiter le cadre */
  border-radius: 8px; /* Ajouter des coins arrondis */
}

</style>