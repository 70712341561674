<template>
  <!-- Fenêtre modale -->
  <div class="modal-overlay">

    <div class="modal-content">
      <p>{{ infosMessage }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name : 'ModalMessage',

  props:{
    infosMessage : String,
  },
}

</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

/* Style pour le contenu de la modale */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  max-width: 80%;
  text-align: center;
  position: relative;
}


</style>

