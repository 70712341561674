<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <img src="../assets/logo.png">
      <a class="navbar-brand" translate="no">My coaching helper</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-if="!isHomePage">
            <router-link to="/" class="nav-link">Accueil</router-link>
          </li>
          <li class="nav-item" v-if="!isDisciplinePage">
            <router-link to="/Disciplines" class="nav-link">Disciplines</router-link>
          </li>
          <li class="nav-item" v-if="!isExercicePage">
            <router-link to="/Exercices" class="nav-link">Exercices</router-link>
          </li>
          <li class="nav-item" v-if="!isMusclePage">
            <router-link to="/Muscles" class="nav-link">Muscles</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div><h1>{{ pageTitle }}</h1></div>
</template>

<script>
 import { useRouter } from 'vue-router';

export default {
  name: 'MenuTitre',
  data() {
    return {
      isMenuOpen: false,
      router: useRouter(),
    };
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    goToHome() {
      this.router.push('/');
    },
  },
  computed: {
    // Vérifier si la route actuelle est la page d'accueil
    isHomePage() {
      return this.$route.path === '/';
    },
    isDisciplinePage() {
      return this.$route.path === '/Disciplines';
    },
    isExercicePage() {
      return this.$route.path === '/Exercices';
    },
    isMusclePage() {
      return this.$route.path === '/Muscles';
    },
  },
};

</script>

<style scoped>
img{
  max-height: 50px;
  margin-right: 10px;
}

</style>